import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mosaic w-full" }
const _hoisted_2 = { class: "mosaic__list flex flex-col gap-[2.4rem] tablet:gap-[3.2rem]" }
const _hoisted_3 = { class: "mosaic__column flex w-full" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "content flex flex-col relative z-30 p-[2.4rem] tablet:p-[4.8rem] h-full max-h-[36.2rem] mt-auto" }
const _hoisted_7 = {
  key: 0,
  class: "tlc-icon-cross text-[3rem] text-white"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "btn btn--read-more mt-[2.4rem] tablet:mt-auto flex gap-[.8rem] items-center text-white tablet:group-hover:gap-[1.8rem]" }
const _hoisted_10 = { class: "mosaic__column mosaic__column--multiple flex flex-col grow w-full h-auto tablet:h-[75.8rem] gap-[2.4rem] tablet:gap-[3.2rem]" }
const _hoisted_11 = ["href"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "content flex flex-col relative z-30 p-[2.4rem] tablet:p-[4.8rem] h-full" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "btn btn--read-more mt-[2.4rem] tablet:mt-auto flex gap-[.8rem] items-center text-white tablet:group-hover:gap-[1.8rem]" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.rows, (row, rowIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: rowIndex,
          class: _normalizeClass([
             'mosaic__row',
             'flex',
             'flex-col',
             'gap-[2.4rem]',
             'tablet:gap-[3.2rem]',
             { 'tablet:flex-row': rowIndex % 2 === 0, 'tablet:flex-row-reverse': rowIndex % 2 !== 0 }
             ])
        }, [
          _createElementVNode("div", _hoisted_3, [
            (row.single)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "mosaic__single group flex flex-col relative h-[36.2rem] tablet:h-auto w-full overflow-hidden",
                  href: row.single.permalink
                }, [
                  _createElementVNode("div", {
                    class: "image *:absolute *:h-[36.2rem] *:tablet:h-[75.8rem] *:w-full *:object-cover z-10 transition-all duration-1000 tablet:group-hover:scale-110",
                    innerHTML: row.single.image
                  }, null, 8, _hoisted_5),
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "overlay content-none absolute bg-black opacity-50 z-20 h-[36.2rem] w-full bottom-0" }, null, -1)),
                  _createElementVNode("div", _hoisted_6, [
                    ($setup.width < 768)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                      : _createCommentVNode("", true),
                    _createElementVNode("h2", {
                      class: "h2 text-white mt-[2rem] tablet:mt-[5.8rem]",
                      innerHTML: row.single.title
                    }, null, 8, _hoisted_8),
                    _createElementVNode("a", _hoisted_9, [
                      _createTextVNode(_toDisplayString($props.textRead), 1),
                      _cache[0] || (_cache[0] = _createElementVNode("i", { class: "tlc-icon-angle text-[.8rem]" }, null, -1))
                    ])
                  ])
                ], 8, _hoisted_4))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.multiple, (post, index) => {
              return (_openBlock(), _createElementBlock("a", {
                key: post.id,
                class: "mosaic__single group flex flex-col relative w-full h-[36.2rem] overflow-hidden",
                href: post.permalink
              }, [
                _createElementVNode("div", {
                  class: "image *:absolute *:h-[36.2rem] *:w-full *:object-cover z-10 transition-all duration-1000 tablet:group-hover:scale-110",
                  innerHTML: post.image
                }, null, 8, _hoisted_12),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "overlay content-none absolute bg-black opacity-50 z-20 h-[36.2rem] w-full" }, null, -1)),
                _createElementVNode("div", _hoisted_13, [
                  _cache[3] || (_cache[3] = _createElementVNode("i", { class: "tlc-icon-cross text-[3rem] text-white" }, null, -1)),
                  _createElementVNode("h2", {
                    class: "h2 text-white mt-[2rem] tablet:mt-[5.8rem]",
                    innerHTML: post.title
                  }, null, 8, _hoisted_14),
                  _createElementVNode("div", _hoisted_15, [
                    _createTextVNode(_toDisplayString($props.textRead), 1),
                    _cache[2] || (_cache[2] = _createElementVNode("i", { class: "tlc-icon-angle" }, null, -1))
                  ])
                ])
              ], 8, _hoisted_11))
            }), 128))
          ])
        ], 2))
      }), 128))
    ])
  ]))
}