import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "filters-switch__select w-full copy2 text-black" }
const _hoisted_2 = {
  key: 0,
  class: "mr-[1.4rem]"
}
const _hoisted_3 = {
  key: 0,
  class: "filters-switch__buttons border-t tablet:border-0"
}
const _hoisted_4 = { class: "copy3" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")
  const _component_el_collapse = _resolveComponent("el-collapse")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_collapse, {
      modelValue: $setup.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.activeTab) = $event)),
      class: "filters gap-[1.6rem] px-[1.4rem] tablet:px-0"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filters, (filter, index) => {
          return (_openBlock(), _createBlock(_component_el_collapse_item, {
            key: index,
            name: filter.name
          }, {
            title: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass([
             {'mr-[.4rem]': $setup.selectedFilters[filter.name] && $setup.selectedFilters[filter.name].length > 0},
             {'mr-[1.4rem]': $setup.selectedFilters[filter.name] && !$setup.selectedFilters[filter.name].length > 0}
            ])
              }, [
                _createTextVNode(_toDisplayString(filter.placeholder), 1),
                ($setup.selectedFilters[filter.name] && $setup.selectedFilters[filter.name].length > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(":")
                    ], 64))
                  : _createCommentVNode("", true)
              ], 2),
              ($setup.selectedFilters[filter.name] && $setup.selectedFilters[filter.name].length > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, " (" + _toDisplayString($setup.selectedFilters[filter.name].length) + ") ", 1))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_checkbox, {
                modelValue: $setup.selectAll[filter.name],
                "onUpdate:modelValue": $event => (($setup.selectAll[filter.name]) = $event),
                onChange: $event => ($setup.toggleSelectAll(filter))
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" All ")
                ])),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"]),
              _createVNode(_component_el_checkbox_group, {
                modelValue: $setup.selectedFilters[filter.name],
                "onUpdate:modelValue": $event => (($setup.selectedFilters[filter.name]) = $event),
                class: "grid grid-cols-1 gap-[1.6rem]"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.list, (item, idx) => {
                    return (_openBlock(), _createBlock(_component_el_checkbox, {
                      key: idx,
                      value: String(item.term_id)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1032, ["name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    ($setup.activeTab != '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          ($setup.width > 768)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "filters__clear flex items-center mt-[2rem] cursor-pointer",
                onClick: _cache[1] || (_cache[1] = (...args) => ($setup.removeAll && $setup.removeAll(...args)))
              }, [
                _cache[4] || (_cache[4] = _createElementVNode("i", { class: "tlc-icon-close text-[0.8rem] mr-[.6rem]" }, null, -1)),
                _createElementVNode("span", _hoisted_4, _toDisplayString($props.remove), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "filters__subbmit btn btn--secondary cursor-pointer mr-auto ml-auto tablet:mr-0 tablet:ml-0 mt-[2rem]",
            onClick: _cache[2] || (_cache[2] = (...args) => ($setup.confirmSelection && $setup.confirmSelection(...args)))
          }, _toDisplayString($props.subbmit), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}