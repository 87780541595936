const documentation = () => {
  if (document.body.classList.contains('page-template-template-documentation')) {
    const main = document.querySelector('.documentation__list');

    const documentation = main.querySelectorAll('.documentation__category');

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const idCategory = entry.target.getAttribute('id');
        const idMenu = `${idCategory}-menu`;
        const menuElement = document.getElementById(idMenu);

        if (entry.isIntersecting) {
          document.querySelectorAll('.documentation__single').forEach(el => el.style.opacity = 0.7);
          menuElement.style.opacity = 1;
        }
      });
    }, observerOptions);

    const documentation_menu = document.querySelector('.documentation__menu--filter');

    const documentation_menu_list = document.querySelectorAll('.documentation__single');


    if (window.innerWidth < 768) {
      documentation_menu.addEventListener('click', function () {
        documentation_menu_list.forEach(s => {
          s.classList.toggle('hidden')
        })
      })
    }

    documentation_menu_list.forEach(s => {
      const idMenu = s.getAttribute('id');
      const idCategory = idMenu.replace('-menu', '');
      const elementToScroll = document.getElementById(idCategory);

      observer.observe(elementToScroll);

      s.addEventListener('click', () => {
        window.scrollTo({top: elementToScroll.offsetTop + main.offsetTop - 100, behavior: 'smooth'});
      });
    });
  }
};

export default documentation;
